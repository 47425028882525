import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui icons
// import Close from "@material-ui/icons/Close";
// import Check from "@material-ui/icons/Check";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// import Button from "components/CustomButtons/Button.js";

import freeDemoStyle from "assets/jss/material-kit-pro-react/views/presentationSections/freeDemoStyle.js";

const useStyles = makeStyles(freeDemoStyle);

export default function SectionFreeDemo() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem
            md={5}
            sm={8}
            className={classes.mlAuto + " " + classes.mrAuto}
          >
            <div className={classes.iconGithub}>
              <i className="fas fa-clipboard-list"></i>
            </div>

            <div>
              <h2 className={classes.title}>Therapies Offered</h2>
              <h5 className={classes.description}>
                I offer a variety of therapies including:
              </h5>
            </div>
            {/* <Button
              href="https://demos.creative-tim.com/material-kit-react/#/?ref=mkpr-free-demo-section-presentation"
              color="rose"
              target="_blank"
              round
            >
              View demo on github
            </Button> */}
          </GridItem>
          <GridItem md={6} sm={12} className={classes.mlAuto}>
            <GridContainer>
              {/* <GridItem md={6} sm={6}>
                <Card className={classNames(classes.card, classes.cardPricing)}>
                  <CardBody>
                    <h3 className={classes.cardTitle}>Therapies</h3>
                    <ul>
                      <li>Cognitive Behavioural Therapy (CBT)</li>
                      <li>Humanistic Therapy</li>
                      <li>Integrative Therapy</li>
                      <li>Person Centred Therapy</li>
                      <li>Solution Focused Brief Therapy</li>
                      <li>Mindfulnes</li>
                    </ul>
                  </CardBody>
                </Card>
              </GridItem> */}
              <GridItem md={12} sm={12}>
                <Card className={classNames(classes.card, classes.cardPricing)}>
                  <CardBody>
                    {/* <h3 className={classes.cardTitle}>Others</h3> */}
                    <ul>
                      <li>Cognitive Behavioural Therapy (CBT)</li>
                      <li>Humanistic therapies</li>
                      <li>Integrative</li>
                      <li>Mindfulness </li>
                      <li>Person-centred therapy</li>
                      <li>Solution focused brief therapy</li>
                    </ul>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
