/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import People from "@material-ui/icons/People";
import Add from "@material-ui/icons/Add";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Badge from "components/Badge/Badge.js";
import Muted from "components/Typography/Muted.js";
import Parallax from "components/Parallax/Parallax.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Button from "components/CustomButtons/Button.js";

import christian from "assets/img/faces/christian.jpg";
import caroline from "assets/img/caroline-profile-1.jpeg";
import oluEletu from "assets/img/examples/olu-eletu.jpg";
import clemOnojeghuo from "assets/img/examples/clem-onojeghuo.jpg";
import cynthiaDelRio from "assets/img/examples/cynthia-del-rio.jpg";
import mariyaGeorgieva from "assets/img/examples/mariya-georgieva.jpg";
import clemOnojegaw from "assets/img/examples/clem-onojegaw.jpg";
import darrenColeshill from "assets/img/examples/darren-coleshill.jpg";
import avatar from "assets/img/faces/avatar.jpg";
import marc from "assets/img/faces/marc.jpg";
import kendall from "assets/img/faces/kendall.jpg";
import cardProfile2Square from "assets/img/faces/card-profile2-square.jpg";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

const useStyles = makeStyles(profilePageStyle);

export default function ProfilePage({ ...rest }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Header
        color="transparent"
        brand="Caroline Sutton"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "info",
        }}
        {...rest}
      />
      <Parallax
        image={require("assets/img/bg3.jpg").default}
        filter="dark"
        className={classes.parallax}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.profile}>
                <div>
                  <img src={caroline} alt="..." className={imageClasses} />
                </div>
                <div className={classes.name}>
                  <h3 className={classes.title}>
                    Caroline Sutton Dip. Counselling MCNS
                  </h3>
                  <h6>COUNSELLOR</h6>
                </div>
              </div>
            </GridItem>
          </GridContainer>
          <div className={classNames(classes.description, classes.textCenter)}>
            <p>
              I am a warm, positive counsellor committed to helping you, the
              client, find the right pathway towards reaching your full
              potential in life. I believe that by understanding issues faced in
              life we can ultimately move forward with renewed and enriched
              perspectives.
            </p>
            <p>
              I am enthusiastic, caring and sensitive to others' needs and offer
              a confidential, supportive and non-judgemental space for you to
              talk about what is troubling you, all within a safe environment.
              The relationship between counsellor and client is based on
              reciprocal trust and this relationship can make the difference
              between a good or bad counselling experience. My aim is for you to
              feel as comfortable as possible within the counselling setting -
              if you feel comfortable then you are more able to fully express
              yourself, which is vital for you to achieve what you need. I am
              both friendly and professional and committed to my work with a
              passion, never taking for granted the privilege it is to be part
              of a client's life journey.
            </p>
          </div>
          <div>
            <h4 className={classes.title}>Qualifications</h4>
            <ul>
              <li>Certificate in Counselling</li>
              <li>Diploma in Brief Therapy</li>
              <li>Diploma in Counselling</li>
              <li>Diploma in Loss and Grief</li>
              <li>Diploma in Therapeutic Practice</li>
              <li>NCS Certificate of Proficiency</li>
            </ul>
            <hr />
            <h4 className={classes.title}>Training</h4>
            <ul>
              <li>Child Protection Policies and Practice</li>
              <li>Equality and Diversity</li>
              <li>Hate Crime</li>
              <li>Lone Working and Safeguarding</li>
              <li>Mental Health in Adults and Adolescents </li>
              <li>Victims of Domestic Abuse</li>
            </ul>
          </div>
          <Clearfix />
        </div>
      </div>
      <Footer
        content={
          <div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()}
            </div>
          </div>
        }
      />
    </div>
  );
}
