import React from "react";
// nodejs library that concatenates classes
// import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Quote from "components/Typography/Quote.js";
// core components

// import blog4 from "assets/img/examples/blog4.jpg";
// import blog3 from "assets/img/examples/blog3.jpg";
// import blog1 from "assets/img/examples/blog1.jpg";

import sectionTextStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle.js";

const useStyles = makeStyles(sectionTextStyle);

export default function SectionText() {
  const classes = useStyles();
  // const imgClasses = classNames(
  //   classes.imgRaised,
  //   classes.imgRounded,
  //   classes.imgFluid
  // );
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>
            The counselling session - what can I expect?
          </h3>
          <p>
            Counselling sessions are flexible depending on your circumstances,
            the presenting problems you are facing and the type of therapeutic
            approach we choose that would be most suited to you. There is no set
            number of sessions agreed, but typically you can expect to have an
            average of between six to twelve sessions. Equally I have had
            successful results with clients after three, four and sometimes even
            one session. There are clients whose issues may be more deep-rooted
            and complex and so need many more sessions.
            <br />
            <br />
            Together, we will review your progress regularly and openly discuss
            what you feel is or isn&apos;t working for you. As a person centred
            counsellor, my aim is to provide you with the best support possible
            using a number of different models and resources, maximising the
            potential to reach the outcome you are looking for.
          </p>
          {/* <Quote
            textClassName={classes.quoteText}
            text="“And thank you for turning my personal jean jacket into a couture piece.”"
            author="Kanye West, Producer."
          /> */}
        </GridItem>
        {/* <GridItem xs={12} sm={10} md={10} className={classes.section}>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <img src={blog4} alt="..." className={imgClasses} />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <img src={blog3} alt="..." className={imgClasses} />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <img src={blog1} alt="..." className={imgClasses} />
            </GridItem>
          </GridContainer>
        </GridItem> */}
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>Are my sessions confidential?</h3>
          <p>
            All you disclose during your session will be treated with the utmost
            confidentiality. The only time confidentiality may be broken is if
            you or another person are at serious risk of harm. This is something
            we would discuss together as disclosures are primarily made with
            your consent only. However, please be aware that by law, counsellors
            have a mandatory duty to disclose information regarding terrorism.
          </p>
        </GridItem>
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>How do I book an appointment?</h3>
          <p>
            Sessions may be booked by telephone call, text, or email, upon which
            I will send confirmation of your appointment.
          </p>
        </GridItem>
        {/* <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>Are my sessions confidential?</h3>
        </GridItem> */}
      </GridContainer>
    </div>
  );
}
