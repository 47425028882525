import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui icons
import CardMembership from "@material-ui/icons/CardMembership";
import CardGiftCard from "@material-ui/icons/CardGiftcard";
// import AttachMoney from "@material-ui/icons/AttachMoney";
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";

import featuresStyle from "assets/jss/material-kit-pro-react/views/pricingSections/featuresStyle.js";

const useStyles = makeStyles(featuresStyle);

export default function SectionFeatures() {
  const classes = useStyles();
  return (
    <div className={classes.featuresSection}>
      <div className={classes.textCenter}>
        <h3 className={classes.title}>Frequently Asked Questions</h3>
      </div>
      <GridContainer>
        <GridItem md={4} sm={4} className={classes.mlAuto}>
          <InfoArea
            title="How do I pay?"
            description="Payments can be made by cash or BACS transfer and are kindly asked for at the end of each session. Online sessions are requested and payable ahead of the session."
            icon={CardMembership}
            iconColor="info"
          />
        </GridItem>
        <GridItem md={4} sm={4} className={classes.mrAuto}>
          <InfoArea
            title="What if I need to cancel my appointment or I change my mind?"
            description="Where possible I ask for a minimum of 24 hours notice, enabling me to reallocate the appointment. If an appointment is missed without notice, there is a cancellation fee of £20 payable."
            icon={CardGiftCard}
            iconColor="success"
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={8} sm={8} className={classes.mrAuto}>
          <InfoArea
            title="What if I arrive early or late for my session?"
            description="As I have no waiting facilities, I advise you not to arrive early for an appointment. However, if you do arrive ahead of your appointment time, there is parking available outside my practice address where you can wait in your vehicle. Alternatively, there are local coffee shops in the village square which is nearby. If you arrive late for a session, I will try to accommodate sixty minutes, however, should that not be possible, the full fee will still apply."
            icon={QuestionAnswer}
            iconColor="rose"
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
