import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
// import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(productStyle);

export default function SectionProduct() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h2 className={classes.title}>Let{"'"}s talk counselling</h2>
          <h5 className={classes.description}>
            Counselling provides you with the opportunity to talk about any
            difficulties you may be experiencing, enabling you to identify areas
            of your life where you would like to see some change. I believe that
            by understanding issues faced in life, we can ultimately move
            forward with renewed and enriched perspectives. My aim is to help
            you understand and make sense of your issues and explore ways of
            approaching them in a potentially different way, enabling you to
            work towards a more fulfilling and positive future.
          </h5>
          <br />
          <h5 className={classes.description}>
            If you have decided to try counselling, you might be feeling anxious
            about your first session. Making the decision to seek support and
            address the issues you are facing is an important first step and in
            itself should be commended. Knowing what to expect from a
            counselling session can help you feel more prepared and less nervous
            about your first appointment and I will be happy to speak with you
            by telephone or email to answer any questions you may have.
            Alternatively, please click on the link to my ‘Useful Information’
            page.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <InfoArea
              title="Flexible"
              description="I am available for phone, online and personal face to face appointments, offering the opportunity to best suit your needs and/or circumstances. Online counselling, such as via zoom or facetime, offers the flexibility for individuals to reach support regardless of distance. This can also be a preferred method of contact for clients who may struggle with the idea of
face to face counselling. I will be more than happy to accommodate your personal choice of counselling."
              icon={Chat}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <InfoArea
              title="Trained Counsellor"
              description="I am a member of the National Counselling Society (NCS) Accredited Membership and work within their Ethical Framework. I undergo regular clinical supervision with a qualified, experienced Supervisor. I have a current CRB certificate and am fully insured."
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>
          {/* <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title="Fingerprint"
              description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
              icon={Fingerprint}
              iconColor="danger"
              vertical
            />
          </GridItem> */}
        </GridContainer>
      </div>
    </div>
  );
}
